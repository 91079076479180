import {
  ADD_USEFULLINK,
  GET_USEFULLINK,
  SET_USEFULLINKS,
  UPDATE_USEFULLINK,
  DELETE_USEFULLINK,
  LOADING_USEFULLINK,
  LOADING_ADD_USEFULLINK,
  USEFULLINK_ERRORS,
} from "../types";

const initialState = {
  usefulLinks: [],
  loading_usefullink: false,
  usefullink_errors: null,
  loading_add_usefullink: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_USEFULLINK:
      return {
        ...state,
        loading_usefullink: true,
        usefullink_errors: null,
      };
    case LOADING_ADD_USEFULLINK:
      return {
        ...state,
        loading_add_usefullink: true,
        usefullink_errors: null,
      };
    case SET_USEFULLINKS:
      return {
        ...state,
        usefulLinks: action.payload,
        loading_usefullink: false,
        usefullink_errors: null,
      };
    case ADD_USEFULLINK:
      return {
        ...state,
        loading_add_usefullink: false,
        usefullink_errors: null,
      };
    case UPDATE_USEFULLINK:
      return {
        ...state,
        loading_add_usefullink: false,
        usefullink_errors: null,
      };
    case DELETE_USEFULLINK:
      return {
        ...state,
        loading_usefullink: false,
        usefullink_errors: null,
      };
    case USEFULLINK_ERRORS:
      return {
        ...state,
        usefullink_errors: action.payload,
        loading_usefullink: false,
        loading_add_usefullink: false,
      };
    default:
      return state;
  }
}
