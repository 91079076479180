import {
  LOADING_LOGIN_USER,
  LOGIN_USER_ERRORS,
  LOADING_GET_USERS,
  GET_USERS_ERRORS,
  SET_USERS,
  SET_AUTHENTICATED,
  SET_UNAUTHENTICATED,
  UPDATE_USER,
  UPDATE_USER_ERROR,
  LOADING_UPDATE_USER,
  CREATE_USER,
  LOADING_CREATE_USER,
  CREATE_USER_ERRORS,
  DELETE_USER,
  DELETE_USER_ERRORS,
  LOADING_DELETE_USER,
  SET_USER,
  SET_USER_ERROR,
  LOADING_USER,
  SET_USER_NEXT,
  LOADING_GET_ROLES,
  GET_ROLES_ERRORS,
  SET_ROLES,
  DARK_MODE,
  SET_SETTINGS,
  GET_SETTINGS_ERROR,
  LOADING_GET_SETTINGS,
  LOADING_GET_DASHBOARD,
  SET_DASHBOARD,
  GET_DASHBOARD_ERROR,
  LOADING_GET_USER_TODOS,
  GET_USER_TODOS_ERRORS,
  SET_USER_TODOS,
  ADD_TODO_USER,
  UPDATE_TODO_USER,
  DELETE_TODO_USER,
  GET_USER_APPOINTMENTS_ERRORS,
  SET_USER_APPOINTMENTS,
  LOADING_GET_USER_APPOINTMENTS,
  LOADING_UPDATE_INFORMATION,
  UPDATE_INFORMATION,
  UPDATE_INFORMATION_ERRORS,
  LOADING_UPLOAD_PROFILE_IMAGE,
  UPLOAD_PROFILE_IMAGE_ERRORS,
  REMOVE_PROFILE_IMAGE_ERRORS,
  LOADING_REMOVE_PROFILE_IMAGE,
  SET_USER_ACTIVITIES,
  LOADING_GET_USER_ACTIVITIES,
  GET_USER_ACTIVITIES_ERRORS,
  GET_PASSWORD_RESET_ERRORS,
  LOADING_GET_PASSWORD_RESET,
  SET_PASSWORD_RESET,
  UPDATE_APP_SETTINGS,
  FILTER_DASHBOARD,
  SET_FORGOT_PASSWORD,
  //validate password reset code
  LOADING_VALIDATE_RESET_CODE,
  VALID_RESET_CODE,
  VALID_RESET_CODE_ERRORS,
  RESET_AUTH_ERROR_MESSAGE,
} from "../types";

const defaultTheme =
  localStorage.Theme !== undefined ? localStorage.Theme : "light";

const initialState = {
  authenticated: false,
  loading_login: false,
  loading_get_users: false,
  loading_create_users: false,
  loading_update_user: false,
  update_user_error: null,
  loading_user: false,
  get_user_errors: null,
  credentials: {},
  role: {},
  message: "",
  employee: {},
  dashboard: {},
  transactions: [],
  dates: [],
  settings: {},
  currency: {},
  users: [],
  user_extras: [],
  pagination: {},
  links: {},
  meta: {},
  login_errors: null,
  create_user_errors: null,
  update_user_errors: null,
  loading_delete_user: false,
  delete_user_errors: null,
  //USER ROLES
  loading_get_roles: false,
  get_roles_errors: null,
  loading_get_settings: false,
  get_settings_errors: null,
  loading_get_dashboard: false,
  get_dashboard_errors: null,
  roles: [],
  mode: defaultTheme,
  //TODOS
  todos: [],
  loading_get_user_todos: false,
  get_user_todos_error: null,
  //appointments
  appointments: [],
  loading_get_user_appointments: false,
  get_user_appointments_error: null,
  loading_update_information: false,
  update_information_errors: null,
  loading_update_profile_image: false,
  update_profile_image_error: null,
  loading_remove_profile_image: false,
  remove_profile_image_error: null,
  activities: [],
  loading_get_user_activities: false,
  get_user_activities_errors: null,
  loading_reset_password: false,
  reset_password_error: null,
  //reset token validation
  validToken: false,
  loadingTokenValidation: false,
  tokenValidationErrors: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RESET_AUTH_ERROR_MESSAGE:
      return {
        ...state,
        message: "",
      };
    case LOADING_VALIDATE_RESET_CODE:
      return {
        ...state,
        validToken: false,
        loadingTokenValidation: true,
        tokenValidationErrors: null,
      };
    case VALID_RESET_CODE:
      return {
        ...state,
        validToken: true,
        loadingTokenValidation: false,
        tokenValidationErrors: null,
      };
    case VALID_RESET_CODE_ERRORS:
      return {
        ...state,
        validToken: false,
        loadingTokenValidation: false,
        tokenValidationErrors: action.payload,
      };
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
        loading_login: false,
        login_errors: null,
      };
    case SET_FORGOT_PASSWORD:
      return {
        ...state,
        loading_login: false,
        login_errors: null,
        message: action.payload.message,
      };
    case DARK_MODE:
      return {
        ...state,
        mode: action.payload,
      };
    case SET_UNAUTHENTICATED:
      return {
        ...state,
        authenticated: false,
        loading_login: false,
        login_errors: null,
      };
    case LOADING_LOGIN_USER:
      return {
        ...state,
        loading_login: false,
        login_errors: null,
      };
    case LOADING_GET_PASSWORD_RESET:
      return {
        ...state,
        loading_reset_password: true,
        reset_password_error: null,
      };
    case LOADING_UPDATE_INFORMATION:
      return {
        ...state,
        loading_update_information: true,
        update_information_errors: null,
      };
    case LOADING_REMOVE_PROFILE_IMAGE:
      return {
        ...state,
        loading_remove_profile_image: true,
        remove_profile_image_error: null,
      };
    case LOADING_GET_USER_ACTIVITIES:
      return {
        ...state,
        loading_get_user_activities: true,
        get_user_activities_errors: null,
      };
    case LOADING_UPLOAD_PROFILE_IMAGE:
      return {
        ...state,
        loading_update_profile_image: true,
        update_profile_image_error: null,
      };
    case LOADING_USER:
      return {
        ...state,
        loading_user: true,
        get_user_errors: null,
      };
    case LOADING_GET_USER_TODOS:
      return {
        ...state,
        loading_get_user_todos: true,
        get_user_todos_error: null,
      };
    case LOADING_GET_USER_APPOINTMENTS:
      return {
        ...state,
        loading_get_user_appointments: true,
        get_user_appointments_error: null,
      };
    case LOADING_GET_DASHBOARD:
      return {
        ...state,
        loading_get_dashboard: true,
        get_dashboard_errors: null,
      };
    case LOADING_GET_SETTINGS:
      return {
        ...state,
        loading_get_settings: true,
        get_settings_errors: null,
      };
    case LOADING_CREATE_USER:
      return {
        ...state,
        loading_create_users: true,
        create_user_errors: null,
      };
    case LOADING_UPDATE_USER:
      return {
        ...state,
        loading_update_user: true,
        login_errors: null,
      };
    case LOADING_DELETE_USER:
      return {
        ...state,
        loading_delete_user: true,
        delete_user_errors: null,
      };
    case LOADING_GET_USERS:
      return {
        ...state,
        loading_get_users: true,
        update_user_errors: null,
      };
    case SET_DASHBOARD:
      return {
        ...state,
        dashboard: action.payload,
        transactions: action.payload.transactions,
        dates: action.payload.year_group,
        loading_get_dashboard: false,
        get_dashboard_errors: null,
      };
    case FILTER_DASHBOARD:
      return {
        ...state,
        dashboard: action.payload,
        loading_get_dashboard: false,
        get_dashboard_errors: null,
      };
    case SET_PASSWORD_RESET:
      return {
        ...state,
        loading_reset_password: false,
        reset_password_error: null,
      };
    case SET_USER_TODOS:
      return {
        ...state,
        todos: action.payload.data,
        loading_get_user_todos: false,
        get_user_todos_error: null,
      };
    case SET_USER_APPOINTMENTS:
      return {
        ...state,
        appointments: action.payload.data,
        loading_get_user_appointments: false,
        get_user_appointments_error: null,
      };
    case SET_USERS:
      return {
        ...state,
        users: action.payload.data,
        user_extras: action.payload,
        loading_get_users: false,
        pagination: action.payload.pagination,
        links: action.payload.links,
        meta: action.payload.meta,
        get_user_errors: null,
      };
    case SET_USER_NEXT:
      return {
        ...state,
        users: [...state.users, ...action.payload.data],
        user_extras: action.payload,
        loading_get_users: false,
        pagination: action.payload.pagination,
        links: action.payload.links,
        meta: action.payload.meta,
        get_user_errors: null,
      };
    case SET_USER:
      // console.log(action.payload);
      return {
        ...state,
        credentials: action.payload,
        role: action.payload.role,
        employee: action.payload.employee,
        loading_user: false,
        get_user_errors: null,
      };
    case UPDATE_INFORMATION:
      // console.log(action.payload);
      return {
        ...state,
        employee: action.payload,
        loading_update_information: false,
        update_information_errors: null,
        loading_update_profile_image: false,
        update_profile_image_error: null,
        loading_remove_profile_image: false,
        remove_profile_image_error: null,
      };
    case DELETE_USER:
      let del = state.users.findIndex((x) => x.id === action.payload.id);
      return {
        ...state,
        users: state.users.filter((item, index) => index !== del),
        loading_delete_user: false,
        delete_user_errors: null,
      };

    case CREATE_USER:
      return {
        ...state,
        users: [action.payload, ...state.users],
        loading_create_users: false,
        create_user_errors: null,
      };

    case UPDATE_APP_SETTINGS:
      // console.log(action.payload);
      return {
        ...state,
        settings: action.payload.settings,
        currency: action.payload.currency,
        loading_get_settings: false,
        get_settings_errors: null,
      };

    case SET_SETTINGS:
      // console.log(action.payload);
      return {
        ...state,
        settings: action.payload,
        currency: action.payload.currency,
        loading_get_settings: false,
        get_settings_errors: null,
      };
    case UPDATE_USER:
      let index = state.users.findIndex((x) => x.id === action.payload.id);
      const dataUpdate = [...state.users];
      dataUpdate[index] = action.payload;
      return {
        ...state,
        users: [...dataUpdate],
        loading_update_user: false,
        add_variant_errors: null,
      };
    case LOGIN_USER_ERRORS:
      return {
        ...state,
        loading_login: false,
        login_errors: action.payload.errors,
        message: action.payload.message,
      };
    case GET_PASSWORD_RESET_ERRORS:
      return {
        ...state,
        loading_reset_password: false,
        reset_password_error: action.payload.errors,
      };
    case GET_USERS_ERRORS:
      return {
        ...state,
        loading_get_users: false,
        get_user_errors: action.payload,
      };
    case UPDATE_INFORMATION_ERRORS:
      return {
        ...state,
        loading_update_information: false,
        update_information_errors: action.payload.errors,
      };
    case UPLOAD_PROFILE_IMAGE_ERRORS:
      return {
        ...state,
        loading_update_profile_image: false,
        update_profile_image_error: action.payload,
      };
    case REMOVE_PROFILE_IMAGE_ERRORS:
      return {
        ...state,
        loading_remove_profile_image: false,
        remove_profile_image_error: action.payload,
      };
    case GET_USER_TODOS_ERRORS:
      return {
        ...state,
        loading_get_user_todos: false,
        get_user_todos_error: action.payload,
      };
    case GET_USER_APPOINTMENTS_ERRORS:
      return {
        ...state,
        loading_get_user_appointments: false,
        get_user_appointments_error: action.payload,
      };
    case UPDATE_USER_ERROR:
      return {
        ...state,
        loading_update_user: false,
        add_variant_errors: action.payload,
      };
    case SET_USER_ACTIVITIES:
      return {
        ...state,
        activities: action.payload.data,
        loading_get_user_activities: false,
        get_user_activities_errors: null,
      };
    case CREATE_USER_ERRORS:
      return {
        ...state,
        loading_create_users: false,
        create_user_errors: action.payload.errors,
      };

    case DELETE_USER_ERRORS:
      return {
        ...state,
        loading_delete_user: false,
        delete_user_errors: action.payload,
      };
    case SET_USER_ERROR:
      return {
        ...state,
        loading_user: false,
        get_user_errors: action.payload,
      };
    //user roles
    case GET_ROLES_ERRORS:
      return {
        ...state,
        loading_get_roles: false,
        get_roles_errors: action.payload,
      };
    case GET_SETTINGS_ERROR:
      return {
        ...state,
        loading_get_settings: false,
        get_settings_errors: action.payload,
      };
    case LOADING_GET_ROLES:
      return {
        ...state,
        loading_get_roles: true,
        get_roles_errors: null,
      };
    case SET_ROLES:
      return {
        ...state,
        roles: action.payload,
        loading_get_roles: false,
        get_roles_errors: null,
      };
    case GET_DASHBOARD_ERROR:
      return {
        ...state,
        loading_get_dashboard: false,
        get_dashboard_errors: action.payload,
      };
    case GET_USER_ACTIVITIES_ERRORS:
      return {
        ...state,
        loading_get_user_activities: false,
        get_user_activities_errors: action.payload,
      };
    case ADD_TODO_USER:
      return {
        ...state,
        todos: [...state.todos, action.payload],
      };
    case UPDATE_TODO_USER:
      let todo_index = state.todos.findIndex((x) => x.id === action.payload.id);
      const todoUpdate = [...state.todos];
      todoUpdate[todo_index] = action.payload;
      return {
        ...state,
        todos: [...todoUpdate],
      };
    case DELETE_TODO_USER:
      let del_todo = state.todos.findIndex((x) => x.id === action.payload.id);
      return {
        ...state,
        todos: state.todos.filter((item, index) => index !== del_todo),
      };
    default:
      return state;
  }
}
