// User reducer types
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const SET_USER = "SET_USER";
export const SET_USER_NEXT = "SET_USER_NEXT";
export const DARK_MODE = "DARK_MODE";

export const SET_USER_ERROR = "SET_USER_ERROR";
export const SET_USERS = "SET_USERS";
export const LOADING_USER = "LOADING_USER";
export const LOADING_GET_USERS = "LOADING_GET_USERS";
export const GET_USERS_ERRORS = "GET_USERS_ERRORS";
export const LOADING_LOGIN_USER = "LOADING_LOGIN_USER";
export const LOGIN_USER_ERRORS = "LOADING_LOGIN_ERRORS";
export const SET_FORGOT_PASSWORD = "SET_FORGOT_PASSWORD";
//update user information
export const UPDATE_INFORMATION = "UPDATE_INFORMATION";
export const LOADING_UPDATE_INFORMATION = "LOADING_UPDATE_INFORMATION";
export const UPDATE_INFORMATION_ERRORS = "UPDATE_INFORMATION_ERRORS";
//upload profile image
export const UPLOAD_PROFILE_IMAGE = "UPLOAD_PROFILE_IMAGE";
export const LOADING_UPLOAD_PROFILE_IMAGE = "LOADING_UPLOAD_PROFILE_IMAGE";
export const UPLOAD_PROFILE_IMAGE_ERRORS = "UPLOAD_PROFILE_IMAGE_ERRORS";
//remove the profile image
export const LOADING_REMOVE_PROFILE_IMAGE = "LOADING_REMOVE_PROFILE_IMAGE";
export const REMOVE_PROFILE_IMAGE_ERRORS = "REMOVE_PROFILE_IMAGE_ERRORS";
//add user
export const ADD_USER = "ADD_USER";
export const LOADING_ADD_USER = "LOADING_ADD_USER";
export const ADD_USER_ERRORS = "ADD_USER_ERRORS";
//get user roles
export const SET_ROLES = "SET_ROLES";
export const LOADING_GET_ROLES = "LOADING_GET_ROLES";
export const GET_ROLES_ERRORS = "GET_ROLES_ERRORS";
//get user todos
export const SET_USER_TODOS = "SET_USER_TODOS";
export const LOADING_GET_USER_TODOS = "LOADING_GET_USER_TODOS";
export const GET_USER_TODOS_ERRORS = "GET_USER_TODOS_ERRORS";
//get user appointments
export const SET_USER_APPOINTMENTS = "SET_USER_APPOINTMENTS";
export const LOADING_GET_USER_APPOINTMENTS = "LOADING_GET_USER_APPOINTMENTS";
export const GET_USER_APPOINTMENTS_ERRORS = "GET_USER_APPOINTMENTS_ERRORS";
//get user activities
export const SET_USER_ACTIVITIES = "SET_USER_ACTIVITIES";
export const LOADING_GET_USER_ACTIVITIES = "LOADING_GET_USER_ACTIVITIES";
export const GET_USER_ACTIVITIES_ERRORS = "GET_USER_ACTIVITIES_ERRORS";
//password reset
export const SET_PASSWORD_RESET = "SET_PASSWORD_RESET";
export const LOADING_GET_PASSWORD_RESET = "LOADING_GET_PASSWORD_RESET";
export const GET_PASSWORD_RESET_ERRORS = "GET_PASSWORD_RESET_ERRORS";
//validate reset code
export const LOADING_VALIDATE_RESET_CODE = "LOADING_VALIDATE_RESET_CODE";
export const VALID_RESET_CODE = "VALID_RESET_CODE";
export const VALID_RESET_CODE_ERRORS = "VALID_RESET_CODE_ERRORS";
//update user
export const LOADING_UPDATE_USER = "LOADING_UPDATE_USER";
export const UPDATE_USER_ERROR = "LOADING_UPDATE_USER";
export const UPDATE_USER = "UPDATE_USER";
// UI reducer types
export const SET_ERRORS = "SET_ERRORS";
export const LOADING_UI = "LOADING_UI";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const LOADING_DATA = "LOADING_DATA";
export const STOP_LOADING_UI = "STOP_LOADING_UI";
// Data reducer types
export const SET_SCREAMS = "SET_SCREAMS";
export const SET_SCREAM = "SET_SCREAM";
export const LIKE_SCREAM = "LIKE_SCREAM";
export const UNLIKE_SCREAM = "UNLIKE_SCREAM";
export const DELETE_SCREAM = "DELETE_SCREAM";
export const POST_SCREAM = "POST_SCREAM";
export const SUBMIT_COMMENT = "SUBMIT_COMMENT";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
//product
export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_PRODUCT = "SET_PRODUCT";
export const PRODUCT_ERRORS = "PRODUCT_ERRORS";
export const LOADING_PRODUCT = "LOADING_PRODUCT";
//add product
export const ADD_PRODUCT = "ADD_PRODUCT";
export const ADD_PRODUCT_ERRORS = "ADD_PRODUCT_ERRORS";
export const LOADING_ADD_PRODUCT = "LOADING_ADD_PRODUCT";
//brands
export const SET_BRANDS = "SET_BRANDS";
export const SET_BRAND = "SET_BRAND";
export const BRAND_ERRORS = "BRAND_ERRORS";
export const LOADING_BRAND = "LOADING_BRAND";
//add brand
export const ADD_BRAND = "ADD_BRAND";
export const LOADING_ADD_BRAND = "LOADING_ADD_BRAND";
export const ADD_BRAND_ERRORS = "ADD_BRAND_ERRORS";
//udpate brand
export const UPDATE_BRAND = "UPDATE_BRAND";
export const LOADING_UPDATE_BRAND = "LOADING_UPDATE_BRAND";
export const UPDATE_BRAND_ERRORS = "UPDATE_BRAND_ERRORS";
//delete brand
export const DELETE_BRAND = "DELETE_BRAND";
export const LOADING_DELETE_BRAND = "LOADING_DELETE_BRAND";
export const DELETE_BRAND_ERRORS = "DELETE_BRAND_ERRORS";
//categories
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_CATEGORY = "SET_CATEGORY";
export const CATEGORIES_ERRORS = "CATEGORIES_ERRORS";
export const LOADING_CATEGORIES = "LOADING_CATEGORIES";

//suppliers
export const SET_SUPPLIERS = "SET_SUPPLIERS";
export const ADD_SUPPLIER = "ADD_SUPPLIER";
export const ADD_SUPPLIER_ERRORS = "ADD_SUPPLIER_ERRORS";
export const SET_SUPPLIER = "SET_SUPPLIER";
export const SUPPLIER_ERRORS = "SUPPLIER_ERRORS";
export const LOADING_SUPPLIER = "LOADING_SUPPLIER";
export const LOADING_ADD_SUPPIER = "LOADING_ADD_SUPPIER";
export const CLEAR_SUPPLIER = "CLEAR_SUPPLIER";
export const LOADING_DELETE_SUPPLIER = "LOADING_DELETE_SUPPLIER";
export const DELETE_SUPPLIER = "DELETE_SUPPLIER";
export const DELETE_SUPPLIER_ERROR = "DELETE_SUPPLIER_ERROR";
export const LOADING_UPDATE_SUPPLIER = "LOADING_UPDATE_SUPPLIER";
export const UPDATE_SUPPLIER = "UPDATE_SUPPLIER";
export const UPDATE_SUPPLIER_ERROR = "UPDATE_SUPPLIER_ERROR";

//manufactuers
export const SET_MANUFACTURERS = "SET_MANUFACTURERS";
export const ADD_MANUFACTURER = "ADD_MANUFACTURER";
export const SET_MANUFACTURER = "SET_MANUFACTURER";
export const MANUFACTURER_ERRORS = "MANUFACTURER_ERRORS";
export const LOADING_MANUFACTURER = "LOADING_MANUFACTURERS";
export const CLEAR_MANUFACTURER = "CLEAR_MANUFACTURER";

//stores
export const CLEAR_STORE = "CLEAR_STORE";

//product types
export const SET_TYPES = "SET_TYPES";
export const SET_TYPE = "SET_TYPE";
export const TYPE_ERRORS = "TYPE_ERRORS";
export const LOADING_TYPE = "LOADING_TYPE";
export const LOADING_ADD_TYPE = "LOADING_ADD_TYPE";
export const ADD_TYPE = "ADD_TYPE";
export const ADD_TYPE_ERROR = "ADD_TYPE_ERROR";
export const LOADING_DELETE_TYPE = "LOADING_DELETE_TYPE";
export const DELETE_TYPE = "DELETE_TYPE";
export const DELETE_TYPE_ERROR = "DELETE_TYPE_ERROR";
export const LOADING_UPDATE_TYPE = "LOADING_UPDATE_TYPE";
export const UPDATE_TYPE = "UPDATE_TYPE";
export const UPDATE_TYPE_ERROR = "UPDATE_TYPE_ERROR";

//variation
export const LOADING_VARIATIONS = "LOADING_VARIATIONS";
export const SET_VARIATIONS = "SET_VARIATIONS";
export const VARIATIONS_ERRORS = "VARIATIONS_ERRORS";

//add variant
export const LOADING_ADD_VARIANT = "LOADING_ADD_VARIANT";
export const ADD_VARIANT = "ADD_VARIANT";
export const ADD_VARIANT_ERRORS = "ADD_VARIANT_ERRORS";

//update variant
export const UPDATE_VARIANT = "UPDATE_VARIANT";
export const LOADING_UPDATE_VARIANT = "LOADING_UPDATE_VARIANT";
export const UPDATE_VARIANT_ERRORS = "UPDATE_VARIANT_ERRORS";

//delete varient

export const DELETE_VARIANT = "DELETE_VARIANT";
export const LOADING_DELETE_VARIANT = "LOADING_DELETE_VARIANT";
export const DELETE_VARIANT_ERRORS = "DELETE_VARIANT_ERRORS";

//update product
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const LOADING_UPDATE_PRODUCT = "LOADING_UPDATE_PRODUCT";
export const UPDATE_PRODUCT_ERRORS = "UPDATE_PRODUCT_ERRORS";

//updates
export const PRODUCT_UPDATE = "PRODUCT_UPDATE";
export const UNSET_PRODUCT_UPDATE = "UNSET_PRODUCT_UPDATE";
export const VARIANT_UPDATE = "VARIANT_UPDATE";
export const UNSET_VARIANT_UPDATE = "UNSET_VARIANT_UPDATE";
export const SUPPLIER_UPDATE = "SUPPLIER_UPDATE";
export const UNSET_SUPPLIER_UPDATE = "UNSET_SUPPLIER_UPDATE";
export const STORE_UPDATE = "STORE_UPDATE";
export const UNSET_STORE_UPDATE = "UNSET_STORE_UPDATE";
export const BRAND_UPDATE = "BRAND_UPDATE";
export const UNSET_BRAND_UPDATE = "UNSET_BRAND_UPDATE";
export const USER_UPDATE = "USER_UPDATE";
export const UNSET_USER_UPDATE = "UNSET_USER_UPDATE";
export const MANUFACTURER_UPDATE = "MANUFACTURER_UPDATE";
export const UNSET_MANUFACTURER_UPDATE = "UNSET_MANUFACTURER_UPDATE";

//create user
export const CREATE_USER = "CREATE_USER";
export const LOADING_CREATE_USER = "LOADING_CRATE_USER";
export const CREATE_USER_ERRORS = "CREATE_USER_ERRORS";

//delete user
export const DELETE_USER = "DELETE_USER";
export const LOADING_DELETE_USER = "LOADING_DELETE_USER";
export const DELETE_USER_ERRORS = "DELETE_USER_ERRORS";

//variant
export const SET_VARIANT = "SET_VARIANT";
export const LOADING_GET_VARIANT = "LOADING_GET_VARIANT";
export const GET_VARIANT_ERRORS = "GET_VARIANT_ERRORS";

//image upload
export const UPLOAD_IMAGES = "UPLOAD_IMAGES";
export const LOADING_IMAGE_UPLOAD = "LOADING_IMAGE_UPLOAD";
export const STOP_LOADING_IMAGE_UPLOAD = "STOP_LOADING_IMAGE_UPLOAD";
export const IMAGE_UPLOAD_ERRORS = "IMAGE_UPLOAD_ERRORS";

//get all variants
export const SET_ALL_VARIANTS = "SET_ALL_VARIANTS";
export const UPDATE_ALL_VARIANT = "UPDATE_ALL_VARIANT";

//get custom variants
export const LOADING_GET_CUSTOM_VARIANTS = "LOADING_GET_CUSTOM_VARIANTS";
export const SET_CUSTOM_VARIANTS = "SET_CUSTOM_VARIANTS";
export const GET_CUSTOM_VARIANTS_ERRORS = "GET_CUSTOM_VARIANTS_ERRORS";

//upload patient document
export const LOADING_UPLOAD_PATIENT_DOCUMENT =
  "LOADING_UPLOAD_PATIENT_DOCUMENT";
export const SET_PATIENT_DOCUMENTS = "SET_PATIENT_DOCUMENTS";
export const UPLOAD_PATIENT_DOCUMENT_ERRORS = "UPLOAD_PATIENT_DOCUMENT_ERRORS";

//get filter custom variants
export const LOADING_GET_FILTER_CUSTOM_VARIANTS =
  "LOADING_GET_FILTER_CUSTOM_VARIANTS";
export const SET_CUSTOM_FILTER_VARIANTS = "SET_CUSTOM_FILTER_VARIANTS";
export const GET_CUSTOM_FILTER_VARIANTS_ERRORS =
  "GET_CUSTOM_FILTER_VARIANTS_ERRORS";

//sheared dispatch
export const ADD_VARIANT_DATA = "ADD_VARIANT_DATA";

//product csv
export const LOADING_IMPORT_PRODUCT = "LOADING_IMPORT_PRODUCT";
export const IMPORT_PRODUCT_SUCCESS = "IMPORT_PRODUCT_SUCCESS";
export const IMPORT_PRODUCT_ERRORS = "IMPORT_PRODUCT_ERRORS";
export const LOADING_CHECK_PRODUCT = "LOADING_CHECK_PRODUCT";
export const CHECK_PRODUCT_SUCCESS = "CHECK_PRODUCT_SUCCESS";
export const CHECK_PRODUCT_ERROR = "CHECK_PRODUCT_ERROR";
export const CLEAR_PRODUCT_CSV = "CLEAR_PRODUCT_CSV";
//product delete actions
export const LOADING_DELETE_PRODUCT = "LOADING_DELETE_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_PRODUCT_ERRORS = "DELETE_PRODUCT_ERRORS";
//patients actions
export const LOADING_GET_PATIENT = "LOADING_GET_PATIENT";
export const GET_PATIENT_ERROR = "GET_PATIENT_ERROR";
export const SET_PATIENT = "SET_PATIENT";
export const LOADING_GET_PATIENTS = "LOADING_GET_PATIENTS";
export const GET_PATIENTS_ERROR = "GET_PATIENTS_ERROR";
export const SET_PATIENTS = "SET_PATIENTS";
export const SET_DELETED_PATIENTS = "SET_DELETED_PATIENTS";
export const ADD_PATIENT = "ADD_PATIENT";
export const ADD_PATIENT_ERROR = "ADD_PATIENT_ERROR";
export const LOADING_ADD_PATIENT = "LOADING_ADD_PATIENT";
export const UPDATE_PATIENT = "UPDATE_PATIENT";
export const UPDATE_PATIENT_GOS = "UPDATE_PATIENT_GOS";
export const LOADING_UPDATE_PATIENT = "LOADING_UPDATE_PATIENT";
export const UPDATE_PATIENT_ERROR = "UPDATE_PATIENT_ERROR";
export const DELETE_PATIENT = "DELETE_PATIENT";
export const LOADING_DELETE_PATIENT = "LOADING_DELETE_PATIENT";
export const DELETE_PATIENT_ERROR = "DELETE_PATIENT_ERROR";
export const LOADING_IMPORT_PATIENT = "LOADING_IMPORT_PATIENT";
export const IMPORT_PATIENT_SUCCESS = "IMPORT_PATIENT_SUCCESS";
export const IMPORT_PATIENT_ERRORS = "IMPORT_PATIENT_ERRORS";
export const LOADING_CHECK_PATIENT = "LOADING_CHECK_PATIENT";
export const CHECK_PATIENT_SUCCESS = "CHECK_PATIENT_SUCCESS";
export const CHECK_PATIENT_ERROR = "CHECK_PATIENT_ERROR";
export const CLEAR_PATIENT_CSV = "CLEAR_PATIENT_CSV";
//active patient
export const SELECT_ACTIVE_PATIENT = "SELECT_ACTIVE_PATIENT";

//title
export const LOADING_GET_TITLE = "LOADING_GET_TITLE";
export const GET_TITLE_ERROR = "GET_TITLE_ERROR";
export const SET_TITLES = "SET_TITLES";

//gender
export const LOADING_GET_GENDER = "LOADING_GET_GENDER";
export const GET_GENDER_ERROR = "GET_GENDER_ERROR";
export const SET_GENDERS = "SET_GENDERS";

//appointments
export const LOADING_GET_APPOINTMENTS = "LOADING_GET_APPOINTMENTS";
export const GET_APPOINTMENTS_ERROR = "GET_APPOINTMENTS_ERROR";
export const SET_APPOINTMENTS = "SET_APPOINTMENTS";
//add appointments
export const LOADING_ADD_APPOINTMENT = "LOADING_ADD_APPOINTMENT";
export const ADD_APPOINTMENT_ERROR = "ADD_APPOINTMENT_ERROR";
export const ADD_APPOINTMENT = "ADD_APPOINTMENT";
export const ADD_APPOINTMENT_PATIENT = "ADD_APPOINTMENT_PATIENT";
//update appointments
export const LOADING_UPDATE_APPOINTMENT = "LOADING_UPDATE_APPOINTMENT";
export const UPDATE_APPOINTMENT_ERROR = "UPDATE_APPOINTMENT_ERROR";
export const UPDATE_APPOINTMENT = "UPDATE_APPOINTMENT";
export const UPDATE_APPOINTMENT_PATIENT = "UPDATE_APPOINTMENT_PATIENT";
//delete appointments
export const LOADING_DELETE_APPOINTMENT = "LOADING_DELETE_APPOINTMENT";
export const DELETE_APPOINTMENT_ERROR = "DELETE_APPOINTMENT_ERROR";
export const DELETE_APPOINTMENT = "DELETE_APPOINTMENT";
export const DELETE_APPOINTMENT_PATIENT = "DELETE_APPOINTMENT_PATIENT";
//STATUSES appointments
export const LOADING_STATUSES_APPOINTMENT = "LOADING_STATUSES_APPOINTMENT";
export const STATUSES_APPOINTMENT_ERROR = "STATUSES_APPOINTMENT_ERROR";
export const STATUSES_APPOINTMENT = "STATUSES_APPOINTMENT";
export const STATUSES_APPOINTMENT_PATIENT = "STATUSES_APPOINTMENT_PATIENT";
//employee
export const LOADING_GET_EMPLOYEES = "LOADING_GET_EMPLOYEES";
export const GET_EMPLOYEES_ERROR = "GET_EMPLOYEES_ERROR";
export const SET_EMPLOYEES = "SET_EMPLOYEES";
//designation
export const LOADING_GET_DESIGNATIONS = "LOADING_GET_DESIGNATIONS";
export const GET_DESIGNATIONS_ERROR = "GET_DESIGNATIONS_ERROR";
export const SET_DESIGNATIONS = "SET_DESIGNATIONS";
// ADD EMPLOYEE
export const LOADING_ADD_EMPLOYEE = "LOADING_ADD_EMPLOYEE";
export const ADD_EMPLOYEE = "ADD_EMPLOYEE";
export const ADD_EMPLOYEE_ERROR = "ADD_EMPLOYEE_ERROR";
// UPDATE EMPLOYEE
export const LOADING_UPDATE_EMPLOYEE = "LOADING_UPDATE_EMPLOYEE";
export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";
export const UPDATE_EMPLOYEE_ERROR = "UPDATE_EMPLOYEE_ERROR";
// DELETE EMPLOYEE
export const LOADING_DELETE_EMPLOYEE = "LOADING_DELETE_EMPLOYEE";
export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";
export const DELETE_EMPLOYEE_ERROR = "DELETE_EMPLOYEE_ERROR";
// CHANGE EMPLOYEE_STATUS
export const LOADING_CHANGE_EMPLOYEE_STATUS = "LOADING_CHANGE_EMPLOYEE_STATUS";
export const CHANGE_EMPLOYEE_STATUS = "CHANGE_EMPLOYEE_STATUS";
export const CHANGE_EMPLOYEE_STATUS_ERROR = "CHANGE_EMPLOYEE_STATUS_ERROR";

//service
export const LOADING_GET_SERVICES = "LOADING_GET_SERVICES";
export const GET_SERVICES_ERROR = "GET_SERVICES_ERROR";
export const SET_SERVICES = "SET_SERVICES";
// add service
export const LOADING_ADD_SERVICE = "LOADING_ADD_SERVICE";
export const ADD_SERVICE_ERROR = "ADD_SERVICE_ERROR";
export const ADD_SERVICE = "ADD_SERVICE";
// delete service
export const LOADING_DELETE_SERVICE = "LOADING_DELETE_SERVICE";
export const DELETE_SERVICE_ERROR = "DELETE_SERVICE_ERROR";
export const DELETE_SERVICE = "DELETE_SERVICE";
// delete service
export const LOADING_UPDATE_SERVICE = "LOADING_UPDATE_SERVICE";
export const UPDATE_SERVICE_ERROR = "UPDATE_SERVICE_ERROR";
export const UPDATE_SERVICE = "UPDATE_SERVICE";

//client
export const LOADING_GET_CLIENTS = "LOADING_GET_CLIENTS";
export const GET_CLIENTS_ERROR = "GET_CLIENTS_ERROR";
export const SET_CLIENTS = "SET_CLIENTS";
// add client
export const LOADING_ADD_CLIENT = "LOADING_ADD_CLIENT";
export const ADD_CLIENT_ERROR = "ADD_CLIENT_ERROR";
export const ADD_CLIENT = "ADD_CLIENT";
// delete client
export const LOADING_DELETE_CLIENT = "LOADING_DELETE_CLIENT";
export const DELETE_CLIENT_ERROR = "DELETE_CLIENT_ERROR";
export const DELETE_CLIENT = "DELETE_CLIENT";
// delete client
export const LOADING_UPDATE_CLIENT = "LOADING_UPDATE_CLIENT";
export const UPDATE_CLIENT_ERROR = "UPDATE_CLIENT_ERROR";
export const UPDATE_CLIENT = "UPDATE_CLIENT";

//clinicalrecords
export const LOADING_GET_CLINICAL_RECORDS = "LOADING_GET_CLINICAL_RECORDS";
export const GET_CLINICAL_RECORDS_ERROR = "GET_CLINICAL_RECORDS_ERROR";
export const SET_CLINICAL_RECORDS = "SET_CLINICAL_RECORDS";
//clinical record
export const LOADING_GET_CLINICAL_RECORD = "LOADING_GET_CLINICAL_RECORD";
export const GET_CLINICAL_RECORD_ERROR = "GET_CLINICAL_RECORD_ERROR";
export const SET_CLINICAL_RECORD = "SET_CLINICAL_RECORD";
// add clinicalrecord
export const LOADING_ADD_CLINICAL_RECORD = "LOADING_ADD_CLINICAL_RECORD";
export const ADD_CLINICAL_RECORD_ERROR = "ADD_CLINICAL_RECORD_ERROR";
export const ADD_CLINICAL_RECORD = "ADD_CLINICAL_RECORD";
export const ADD_CLINICAL_RECORD_PATIENT = "ADD_CLINICAL_RECORD_PATIENT";
// delete clinicalrecord
export const LOADING_DELETE_CLINICAL_RECORD = "LOADING_DELETE_CLINICAL_RECORD";
export const DELETE_CLINICAL_RECORD_ERROR = "DELETE_CLINICAL_RECORD_ERROR";
export const DELETE_CLINICAL_RECORD = "DELETE_CLINICAL_RECORD";
// delete clinicalrecord
export const LOADING_UPDATE_CLINICAL_RECORD = "LOADING_UPDATE_CLINICAL_RECORD";
export const UPDATE_CLINICAL_RECORD_ERROR = "UPDATE_CLINICAL_RECORD_ERROR";
export const UPDATE_CLINICAL_RECORD = "UPDATE_CLINICAL_RECORD";
// update clinical history and symptoms
export const UPDATE_HISTORY_AND_SYMPTOMS = "UPDATE_HISTORY_AND_SYMPTOMS";
export const UPDATE_PRE_SCREEN = "UPDATE_PRE_SCREEN";
export const UPDATE_SUPPLEMENTARY = "UPDATE_SUPPLEMENTARY";
export const UPDATE_ADDITIONAL_TEST = "UPDATE_ADDITIONAL_TEST";
export const UPDATE_RECOMMENDATIONS = "UPDATE_RECOMMENDATIONS";
export const UPDATE_OCULAR_HEALTH = "UPDATE_OCULAR_HEALTH";
export const UPDATE_SUBJECTIVE = "UPDATE_SUBJECTIVE";
export const UPDATE_COMMENT_ADVICE = "UPDATE_COMMENT_ADVICE";
export const UPDATE_DRAWING_SECTION = "UPDATE_DRAWING_SECTION";
export const UPDATE_IMAGE_SECTION = "UPDATE_IMAGE_SECTION";
export const UPDATE_MUSCLE_BALANCE_DRAWING_SECTION =
  "UPDATE_MUSCLE_BALANCE_UPDATE_DRAWING_SECTION";
export const UPDATE_MUSCLE_BALANCE_UPDATE_IMAGE_SECTION =
  "UPDATE_MUSCLE_BALANCE_UPDATE_IMAGE_SECTION";
export const UPDATE_CONTACT_LENSES = "UPDATE_CONTACT_LENSES";
export const SET_PATIENT_CLINICAL_STATUS = "SET_PATIENT_CLINICAL_STATUS";
export const SELECT_CLINICAL_RECORD = "SELECT_CLINICAL_RECORD";
export const DELETE_PATIENT_CLINICAL = "DELETE_PATIENT_CLINICAL";
//media
export const LOADING_GET_MEDIAS = "LOADING_GET_MEDIAS";
export const GET_MEDIAS_ERROR = "GET_MEDIAS_ERROR";
export const SET_MEDIAS = "SET_MEDIAS";
// add media
export const LOADING_ADD_MEDIA = "LOADING_ADD_MEDIA";
export const ADD_MEDIA_ERROR = "ADD_MEDIA_ERROR";
export const ADD_MEDIA = "ADD_MEDIA";
// delete media
export const LOADING_DELETE_MEDIA = "LOADING_DELETE_MEDIA";
export const DELETE_MEDIA_ERROR = "DELETE_MEDIA_ERROR";
export const DELETE_MEDIA = "DELETE_MEDIA";
// delete media
export const LOADING_UPDATE_MEDIA = "LOADING_UPDATE_MEDIA";
export const UPDATE_MEDIA_ERROR = "UPDATE_MEDIA_ERROR";
export const UPDATE_MEDIA = "UPDATE_MEDIA";

//sales
export const LOADING_GET_SALES = "LOADING_GET_SALES";
export const GET_SALES_ERROR = "GET_SALES_ERROR";
export const SET_SALES = "SET_SALES";
//sale
export const LOADING_GET_SALE = "LOADING_GET_SALE";
export const GET_SALE_ERROR = "GET_SALE_ERROR";
export const SET_SALE = "SET_SALE";
// add sale
export const LOADING_ADD_SALE = "LOADING_ADD_SALE";
export const ADD_SALE_ERROR = "ADD_SALE_ERROR";
export const ADD_SALE = "ADD_SALE";
// delete sale
export const LOADING_DELETE_SALE = "LOADING_DELETE_SALE";
export const DELETE_SALE_ERROR = "DELETE_SALE_ERROR";
export const DELETE_SALE = "DELETE_SALE";
export const DELETE_PATIENT_SALE = "DELETE_PATIENT_SALE";
// delete sale
export const LOADING_UPDATE_SALE = "LOADING_UPDATE_SALE";
export const UPDATE_SALE_ERROR = "UPDATE_SALE_ERROR";
export const UPDATE_SALE = "UPDATE_SALE";
// get all sold items
export const LOADING_GET_SOLD_ITEMS = "LOADING_GET_SOLD_ITEMS";
export const SET_SOLD_ITEMS = "SET_SOLD_ITEMS";
export const GET_SOLD_ITEMS_ERROR = "GET_SOLD_ITEMS_ERROR";

//get prescription data
export const LOADING_GET_PRESCIPTION_DATA = "LOADING_GET_PRESCIPTION_DATA";
export const GET_PRESCIPTION_ERROR = "GET_PRESCIPTION_ERROR";
export const SET_PRESCRIPTION_DATA = "SET_PRESCRIPTION_DATA";
export const CHANGE_PRESCRIPTION_USAGE = "CHANGE_PRESCRIPTION_USAGE";
// add prescription
export const LOADING_ADD_PRESCIPTION = "LOADING_ADD_PRESCIPTION";
export const ADD_PRESCIPTION_ERROR = "ADD_PRESCIPTION_ERROR";
export const ADD_PRESCIPTION = "ADD_PRESCIPTION";
// delete prescription
export const LOADING_DELETE_PRESCIPTION = "LOADING_DELETE_PRESCIPTION";
export const DELETE_PRESCIPTION_ERROR = "DELETE_PRESCIPTION_ERROR";
export const DELETE_PRESCIPTION = "DELETE_PRESCIPTION";
// update prescription
export const LOADING_UPDATE_PRESCIPTION = "LOADING_UPDATE_PRESCIPTION";
export const UPDATE_PRESCIPTION_ERROR = "UPDATE_PRESCIPTION_ERROR";
export const UPDATE_PRESCIPTION = "UPDATE_PRESCIPTION";
// account
export const LOADING_GET_ACCOUNTS = "LOADING_GET_ACCOUNTS";
export const SET_ACCOUNTS = "SET_ACCOUNTS";
export const GET_ACCOUNTS_ERROR = "GET_ACCOUNTS_ERROR";
// account
export const LOADING_ADD_ACCOUNT = "LOADING_ADD_ACCOUNT";
export const ADD_ACCOUNT = "ADD_ACCOUNT";
export const ADD_ACCOUNT_ERROR = "ADD_ACCOUNT_ERROR";
// account
export const LOADING_UPDATE_ACCOUNT = "LOADING_UPDATE_ACCOUNT";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const UPDATE_ACCOUNT_ERROR = "UPDATE_ACCOUNT_ERROR";
// currencies
export const LOADING_GET_CURRENCIES = "LOADING_GET_CURRENCIES";
export const SET_CURRENCIES = "SET_CURRENCIES";
export const GET_CURRENCIES_ERROR = "GET_CURRENCIES_ERROR";
// account
export const LOADING_DELETE_ACCOUNT = "LOADING_DELETE_ACCOUNT";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const DELETE_ACCOUNT_ERROR = "DELETE_ACCOUNT_ERROR";
// GET TRANSACTION
export const LOADING_GET_TRANSACTIONS = "LOADING_GET_TRANSACTIONS";
export const SET_TRANSACTIONS = "SET_TRANSACTIONS";
export const GET_TRANSACTIONS_ERROR = "GET_TRANSACTIONS_ERROR";
// ADD TRANSACTION
export const LOADING_ADD_TRANSACTION = "LOADING_ADD_TRANSACTION";
export const ADD_TRANSACTION = "ADD_TRANSACTION";
export const ADD_TRANSACTION_ERROR = "ADD_TRANSACTION_ERROR";
// UPDATE TRANSACTION
export const LOADING_UPDATE_TRANSACTION = "LOADING_UPDATE_TRANSACTION";
export const UPDATE_TRANSACTION = "UPDATE_TRANSACTION";
export const UPDATE_TRANSACTION_ERROR = "UPDATE_TRANSACTION_ERROR";
// DELETE TRANSACTION
export const LOADING_DELETE_TRANSACTION = "LOADING_DELETE_TRANSACTION";
export const DELETE_TRANSACTION = "DELETE_TRANSACTION";
export const DELETE_TRANSACTION_ERROR = "DELETE_TRANSACTION_ERROR";

// GET MESSAGE CHANNELS
export const LOADING_GET_MESSAGES_CHANNELS = "LOADING_GET_MESSAGES_CHANNELS";
export const SET_MESSAGES_CHANNELS = "SET_MESSAGES_CHANNELS";
export const GET_MESSAGES_ERROR_CHANNELS = "GET_MESSAGES_ERROR_CHANNELS";
// GET MESSAGE
export const LOADING_GET_MESSAGES = "LOADING_GET_MESSAGES";
export const SET_MESSAGES = "SET_MESSAGES";
export const GET_MESSAGES_ERROR = "GET_MESSAGES_ERROR";
// ADD MESSAGE
export const LOADING_ADD_MESSAGE = "LOADING_ADD_MESSAGE";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const ADD_MESSAGE_ERROR = "ADD_MESSAGE_ERROR";
//ADD MESSAGE PATIENT
export const LOADING_ADD_MESSAGE_PATIENT = "LOADING_ADD_MESSAGE_PATIENT";
export const ADD_MESSAGE_PATIENT = "ADD_MESSAGE_PATIENT";
export const ADD_MESSAGE_PATIENT_ERROR = "ADD_MESSAGE_PATIENT_ERROR";
// UPDATE MESSAGE
export const LOADING_UPDATE_MESSAGE = "LOADING_UPDATE_MESSAGE";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";
export const UPDATE_MESSAGE_ERROR = "UPDATE_MESSAGE_ERROR";
// DELETE MESSAGE
export const LOADING_DELETE_MESSAGE = "LOADING_DELETE_MESSAGE";
export const DELETE_MESSAGE = "DELETE_MESSAGE";
export const DELETE_MESSAGE_ERROR = "DELETE_MESSAGE_ERROR";
// MESSAGE PATIENT
export const LOADING_UPDATE_MESSAGE_PATIENT = "LOADING_UPDATE_MESSAGE_PATIENT";
export const UPDATE_MESSAGE_PATIENT = "UPDATE_MESSAGE_PATIENT";
export const UPDATE_MESSAGE_PATIENT_ERROR = "UPDATE_MESSAGE_PATIENT_ERROR";
// DELETE MESSAGE_PATIENT
export const LOADING_DELETE_MESSAGE_PATIENT = "LOADING_DELETE_MESSAGE_PATIENT";
export const DELETE_MESSAGE_PATIENT = "DELETE_MESSAGE_PATIENT";
export const DELETE_MESSAGE_PATIENT_ERROR = "DELETE_MESSAGE_PATIENT_ERROR";
//

// GET PAYMENT
export const LOADING_GET_PAYMENTS = "LOADING_GET_PAYMENTS";
export const SET_PAYMENTS = "SET_PAYMENTS";
export const GET_PAYMENTS_ERROR = "GET_PAYMENTS_ERROR";
// ADD PAYMENT
export const LOADING_ADD_PAYMENT = "LOADING_ADD_PAYMENT";
export const ADD_PAYMENT = "ADD_PAYMENT";
export const ADD_PAYMENT_ERROR = "ADD_PAYMENT_ERROR";
// UPDATE PAYMENT
export const LOADING_UPDATE_PAYMENT = "LOADING_UPDATE_PAYMENT";
export const UPDATE_PAYMENT = "UPDATE_PAYMENT";
export const UPDATE_PAYMENT_ERROR = "UPDATE_PAYMENT_ERROR";
// DELETE PAYMENT
export const LOADING_DELETE_PAYMENT = "LOADING_DELETE_PAYMENT";
export const DELETE_PAYMENT = "DELETE_PAYMENT";
export const DELETE_PAYMENT_ERROR = "DELETE_PAYMENT_ERROR";

export const UPDATE_DISPENSING_SUBJECTIVE = "UPDATE_DISPENSING_SUBJECTIVE";
export const CLEAR_DISPENSING_SUBJECTIVE = "CLEAR_DISPENSING_SUBJECTIVE";
export const CLEAR_DISPENSING_RECORD = "CLEAR_DISPENSING_RECORD";

// GET DISPENSING
export const LOADING_GET_DISPENSINGS = "LOADING_GET_DISPENSINGS";
export const SET_DISPENSINGS = "SET_DISPENSINGS";
export const GET_DISPENSINGS_ERROR = "GET_DISPENSINGS_ERROR";
//change dispensing product
export const CHANGE_DISPENSING_PRODUCT = "CHANGE_DISPENSING_PRODUCT";
// GET DISPENSING ITEMS
export const LOADING_GET_DISPENSING_ITEMS = "LOADING_GET_DISPENSING_ITEMS";
export const SET_DISPENSING_ITEMS = "SET_DISPENSING_ITEMS";
export const GET_DISPENSING_ITEMS_ERROR = "GET_DISPENSING_ITEMS_ERROR";
// ADD DISPENSING
export const LOADING_ADD_DISPENSING = "LOADING_ADD_DISPENSING";
export const ADD_DISPENSING = "ADD_DISPENSING";
export const ADD_PATIENT_DISPENSING = "ADD_PATIENT_DISPENSING";
export const ADD_DISPENSING_ERROR = "ADD_DISPENSING_ERROR";
// UPDATE DISPENSING
export const LOADING_UPDATE_DISPENSING = "LOADING_UPDATE_DISPENSING";
export const UPDATE_DISPENSING = "UPDATE_DISPENSING";
export const UPDATE_PATIENT_DISPENSING = "UPDATE_PATIENT_DISPENSING";
export const UPDATE_DISPENSING_ERROR = "UPDATE_DISPENSING_ERROR";
// DELETE DISPENSING
export const LOADING_DELETE_DISPENSING = "LOADING_DELETE_DISPENSING";
export const DELETE_DISPENSING = "DELETE_DISPENSING";
export const DELETE_PATIENT_DISPENSING = "DELETE_PATIENT_DISPENSING";
export const DELETE_DISPENSING_ERROR = "DELETE_DISPENSING_ERROR";
// MARK AS PAID
export const LOADING_PAY_DISPENSING = "LOADING_PAY_DISPENSING";
export const PAY_DISPENSING = "PAY_DISPENSING";
export const PAY_PATIENT_DISPENSING = "PAY_PATIENT_DISPENSING";
export const PAY_DISPENSING_ERROR = "PAY_DISPENSING_ERROR";
// MARK AS PAID
export const LOADING_GET_STATUSES = "LOADING_GET_STATUSES";
export const SET_DISPENSING_STATUSES = "SET_DISPENSING_STATUSES";
export const GET_STATUSES_ERROR = "GET_STATUSES_ERROR";
//update dispensing status
export const LOADING_UPDATE_DISPENSING_STATUS =
  "LOADING_UPDATE_DISPENSING_STATUS";
export const UPDATE_DISPENSING_STATUS = "UPDATE_DISPENSING_STATUS";
export const UPDATE_DISPENSING_STATUS_ERROR = "UPDATE_DISPENSING_STATUS_ERROR";
//get settings
export const LOADING_GET_SETTINGS = "LOADING_GET_SETTINGS";
export const SET_SETTINGS = "SET_SETTINGS";
export const GET_SETTINGS_ERROR = "GET_SETTINGS_ERROR";
//dashboard
export const LOADING_GET_DASHBOARD = "LOADING_GET_DASHBOARD";
export const SET_DASHBOARD = "SET_DASHBOARD";
export const FILTER_DASHBOARD = "FILTER_DASHBOARD";
export const GET_DASHBOARD_ERROR = "GET_DASHBOARD_ERROR";

// GET RECALL
export const LOADING_GET_RECALLS = "LOADING_GET_RECALLS";
export const SET_RECALLS = "SET_RECALLS";
export const GET_RECALLS_ERROR = "GET_RECALLS_ERROR";
// ADD RECALL
export const LOADING_ADD_RECALL = "LOADING_ADD_RECALL";
export const ADD_RECALL = "ADD_RECALL";
export const ADD_RECALL_PATIENT = "ADD_RECALL_PATIENT";
export const ADD_RECALL_ERROR = "ADD_RECALL_ERROR";
// UPDATE RECALL
export const LOADING_UPDATE_RECALL = "LOADING_UPDATE_RECALL";
export const UPDATE_RECALL = "UPDATE_RECALL";
export const UPDATE_RECALL_PATIENT = "UPDATE_RECALL_PATIENT";
export const UPDATE_RECALL_ERROR = "UPDATE_RECALL_ERROR";
// DELETE RECALL
export const LOADING_DELETE_RECALL = "LOADING_DELETE_RECALL";
export const DELETE_RECALL = "DELETE_RECALL";
export const DELETE_RECALL_PATIENT = "DELETE_RECALL_PATIENT";
export const DELETE_RECALL_ERROR = "DELETE_RECALL_ERROR";
// GET RECALL TYPES
export const LOADING_GET_RECALL_TYPES = "LOADING_GET_RECALL_TYPES";
export const SET_RECALL_TYPES = "SET_RECALL_TYPES";
export const GET_RECALL_TYPES_ERROR = "GET_RECALL_TYPES_ERROR";
// GET RECALL CATEGORIES
export const LOADING_GET_RECALL_CATEGORIES = "LOADING_GET_RECALL_CATEGORIES";
export const SET_RECALL_CATEGORIES = "SET_RECALL_CATEGORIES";
export const GET_RECALL_CATEGORIES_ERROR = "GET_RECALL_CATEGORIES_ERROR";
//ADD RECALL CATEGORY
export const LOADING_ADD_RECALL_CATEGORY = "LOADING_ADD_RECALL_CATEGORY";
export const ADD_RECALL_CATEGORY = "ADD_RECALL_CATEGORY";
export const ADD_RECALL_CATEGORY_ERROR = "ADD_RECALL_CATEGORY_ERROR";
// UPDATE RECALL_CATEGORY
export const LOADING_UPDATE_RECALL_CATEGORY = "LOADING_UPDATE_RECALL_CATEGORY";
export const UPDATE_RECALL_CATEGORY = "UPDATE_RECALL_CATEGORY";
export const UPDATE_RECALL_CATEGORY_ERROR = "UPDATE_RECALL_CATEGORY_ERROR";
// DELETE RECALL_CATEGORY
export const LOADING_DELETE_RECALL_CATEGORY = "LOADING_DELETE_RECALL_CATEGORY";
export const DELETE_RECALL_CATEGORY = "DELETE_RECALL_CATEGORY";
export const DELETE_RECALL_CATEGORY_ERROR = "DELETE_RECALL_CATEGORY_ERROR";
//notes
// GET NOTE
export const LOADING_GET_NOTES = "LOADING_GET_NOTES";
export const SET_NOTES = "SET_NOTES";
export const GET_NOTES_ERROR = "GET_NOTES_ERROR";
// ADD NOTE
export const LOADING_ADD_NOTE = "LOADING_ADD_NOTE";
export const ADD_NOTE = "ADD_NOTE";
export const ADD_NOTE_PATIENT = "ADD_NOTE_PATIENT";
export const ADD_NOTE_ERROR = "ADD_NOTE_ERROR";
// UPDATE NOTE
export const LOADING_UPDATE_NOTE = "LOADING_UPDATE_NOTE";
export const UPDATE_NOTE = "UPDATE_NOTE";
export const UPDATE_NOTE_PATIENT = "UPDATE_NOTE_PATIENT";
export const UPDATE_NOTE_ERROR = "UPDATE_NOTE_ERROR";
// DELETE NOTE
export const LOADING_DELETE_NOTE = "LOADING_DELETE_NOTE";
export const DELETE_NOTE = "DELETE_NOTE";
export const DELETE_NOTE_PATIENT = "DELETE_NOTE_PATIENT";
export const DELETE_NOTE_ERROR = "DELETE_NOTE_ERROR";
// SPECTACLE DISPENSING
export const ADD_SPECTACLE_DISPENSING = "ADD_SPECTACLE_DISPENSING";
export const REMOVE_SPECTACLE_DISPENSING = "REMOVE_SPECTACLE_DISPENSING";
export const EDIT_SPECTACLE_DISPENSING = "EDIT_SPECTACLE_DISPENSING";
// GET NOTE TYPES
export const LOADING_GET_NOTE_TYPES = "LOADING_GET_NOTE_TYPES";
export const SET_NOTE_TYPES = "SET_NOTE_TYPES";
export const GET_NOTE_TYPES_ERROR = "GET_NOTE_TYPES_ERROR";
// GET NOTE CATEGORIES
export const LOADING_GET_NOTE_CATEGORIES = "LOADING_GET_NOTE_CATEGORIES";
export const SET_NOTE_CATEGORIES = "SET_NOTE_CATEGORIES";
export const GET_NOTE_CATEGORIES_ERROR = "GET_NOTE_CATEGORIES_ERROR";
// UPDATE APP SETTINGS
export const LOADING_UPDATE_APP_SETTINGS = "LOADING_UPDATE_APP_SETTINGS";
export const UPDATE_APP_SETTINGS = "UPDATE_APP_SETTINGS";
export const UPDATE_APP_SETTINGS_ERROR = "UPDATE_APP_SETTINGS_ERROR";
//todos
export const LOADING_GET_TODOS = "LOADING_GET_TODOS";
export const GET_TODOS_ERROR = "GET_TODOS_ERROR";
export const SET_TODOS = "SET_TODOS";
// add todo
export const LOADING_ADD_TODO = "LOADING_ADD_TODO";
export const ADD_TODO_ERROR = "ADD_TODO_ERROR";
export const ADD_TODO = "ADD_TODO";
export const ADD_TODO_PATIENT = "ADD_TODO_PATIENT";
export const ADD_TODO_USER = "ADD_TODO_USER";
// delete todo
export const LOADING_DELETE_TODO = "LOADING_DELETE_TODO";
export const DELETE_TODO_ERROR = "DELETE_TODO_ERROR";
export const DELETE_TODO = "DELETE_TODO";
export const DELETE_TODO_PATIENT = "DELETE_TODO_PATIENT";
export const DELETE_TODO_USER = "DELETE_TODO_USER";
// delete todo
export const LOADING_UPDATE_TODO = "LOADING_UPDATE_TODO";
export const UPDATE_TODO_ERROR = "UPDATE_TODO_ERROR";
export const UPDATE_TODO = "UPDATE_TODO";
export const UPDATE_TODO_PATIENT = "UPDATE_TODO_PATIENT";
export const UPDATE_TODO_USER = "UPDATE_TODO_USER";
//voucher
export const LOADING_GET_VOUCHERS = "LOADING_GET_VOUCHERS";
export const GET_VOUCHERS_ERROR = "GET_VOUCHERS_ERROR";
export const SET_VOUCHERS = "SET_VOUCHERS";
//all vouchers
export const LOADING_GET_ALL_VOUCHERS = "LOADING_GET_ALL_VOUCHERS";
export const GET_ALL_VOUCHERS_ERROR = "GET_ALL_VOUCHERS_ERROR";
export const SET_ALL_VOUCHERS = "SET_ALL_VOUCHERS";
// add voucher
export const LOADING_ADD_VOUCHER = "LOADING_ADD_VOUCHER";
export const ADD_VOUCHER_ERROR = "ADD_VOUCHER_ERROR";
export const ADD_VOUCHER = "ADD_VOUCHER";
// delete voucher
export const LOADING_DELETE_VOUCHER = "LOADING_DELETE_VOUCHER";
export const DELETE_VOUCHER_ERROR = "DELETE_VOUCHER_ERROR";
export const DELETE_VOUCHER = "DELETE_VOUCHER";
// delete voucher
export const LOADING_UPDATE_VOUCHER = "LOADING_UPDATE_VOUCHER";
export const UPDATE_VOUCHER_ERROR = "UPDATE_VOUCHER_ERROR";
export const UPDATE_VOUCHER = "UPDATE_VOUCHER";
//get voucher options
export const LOADING_GET_VOUCHER_OPTIONS = "LOADING_GET_VOUCHER_OPTIONS";
export const GET_VOUCHER_OPTIONS_ERROR = "GET_VOUCHER_OPTIONS_ERROR";
export const SET_VOUCHER_OPTIONS = "SET_VOUCHER_OPTIONS";
//get payments
export const LOADING_GET_SALES_PAYMENT = "LOADING_GET_SALES_PAYMENT";
export const GET_SALES_PAYMENT_ERROR = "GET_SALES_PAYMENT_ERROR";
export const SET_SALES_PAYMENT = "SET_SALES_PAYMENT";
// add voucher options
export const LOADING_ADD_VOUCHER_OPTION = "LOADING_ADD_VOUCHER_OPTION";
export const ADD_VOUCHER_OPTION_ERROR = "ADD_VOUCHER_OPTION_ERROR";
export const ADD_VOUCHER_OPTION = "ADD_VOUCHER_OPTION";
// delete voucher options
export const LOADING_DELETE_VOUCHER_OPTION = "LOADING_DELETE_VOUCHER_OPTION";
export const DELETE_VOUCHER_OPTION_ERROR = "DELETE_VOUCHER_OPTION_ERROR";
export const DELETE_VOUCHER_OPTION = "DELETE_VOUCHER_OPTION";
// delete voucher options
export const LOADING_UPDATE_VOUCHER_OPTION = "LOADING_UPDATE_VOUCHER_OPTION";
export const UPDATE_VOUCHER_OPTION_ERROR = "UPDATE_VOUCHER_OPTION_ERROR";
export const UPDATE_VOUCHER_OPTION = "UPDATE_VOUCHER_OPTION";
//tax
export const LOADING_GET_TAXES = "LOADING_GET_TAXES";
export const GET_TAXES_ERROR = "GET_TAXES_ERROR";
export const SET_TAXES = "SET_TAXES";
// add tax
export const LOADING_ADD_TAX = "LOADING_ADD_TAX";
export const ADD_TAX_ERROR = "ADD_TAX_ERROR";
export const ADD_TAX = "ADD_TAX";
// delete tax
export const LOADING_DELETE_TAX = "LOADING_DELETE_TAX";
export const DELETE_TAX_ERROR = "DELETE_TAX_ERROR";
export const DELETE_TAX = "DELETE_TAX";
// delete tax
export const LOADING_UPDATE_TAX = "LOADING_UPDATE_TAX";
export const UPDATE_TAX_ERROR = "UPDATE_TAX_ERROR";
export const UPDATE_TAX = "UPDATE_TAX";
// iternal actions
export const SET_ITEMS_FROM_DISPENSING = "SET_ITEMS_FROM_DISPENSING";
export const SET_PREVIOUS_TRANSACTION = "SET_PREVIOUS_TRANSACTION";
export const SET_ACTIVE_PATIENT_TAB = "SET_ACTIVE_PATIENT_TAB";
//external actions
export const SET_POST_CODE_AUTO_SUGGEST = "SET_POST_CODE_AUTO_SUGGEST";
export const LOADING_POST_CODE_AUTO_SUGGEST = "LOADING_POST_CODE_AUTO_SUGGEST";
export const POST_CODE_AUTO_SUGGEST_ERROR = "POST_CODE_AUTO_SUGGEST_ERROR";
export const VALIDATE_POST_CODE = "VALIDATE_POST_CODE";
export const SET_CITY = "SET_CITY";
export const CLEAR_ADDRESSES = "CLEAR_ADDRESSES";
//analytics
export const LOADING_GET_ANALYTICS = "LOADING_GET_ANALYTICS";
export const SET_ANALYTICS = "SET_ANALYTICS";
export const GET_ANALYTICS_ERRORS = "GET_ANALYTICS_ERRORS";
//error log
export const LOG_ERROR = "LOG_ERROR";
export const LOGGING_ERROR = "LOGGING_ERROR";
export const ERROR_LOGGING_ERROR = "ERROR_LOGGING_ERROR";
//reset auth page error
export const RESET_AUTH_ERROR_MESSAGE = "RESET_AUTH_ERROR_MESSAGE";
//stores
export const SET_STORES = "SET_STORES";
export const SET_STORE = "SET_STORE";
export const STORE_ERRORS = "STORE_ERRORS";
export const LOADING_STORE = "LOADING_STORE";
//add store
export const ADD_STORE = "ADD_STORE";
export const LOADING_ADD_STORE = "LOADING_ADD_STORE";
export const ADD_STORE_ERRORS = "ADD_STORE_ERRORS";
//udpate store
export const UPDATE_STORE = "UPDATE_STORE";
export const LOADING_UPDATE_STORE = "LOADING_UPDATE_STORE";
export const UPDATE_STORE_ERRORS = "UPDATE_STORE_ERRORS";
//delete store
export const DELETE_STORE = "DELETE_STORE";
export const LOADING_DELETE_STORE = "LOADING_DELETE_STORE";
export const DELETE_STORE_ERRORS = "DELETE_STORE_ERRORS";
export const UPDATE_REFERRAL = "UPDATE_REFERRAL";

export const UPDATE_CONTACT_LENS_HISTORY_AND_SYMPTOMS =
  "UPDATE_CONTACT_LENS_HISTORY_AND_SYMPTOMS";
export const UPDATE_CONTACT_LENS_BASELINE_MEASUREMENTS =
  "UPDATE_CONTACT_LENS_BASELINE_MEASUREMENTS";
export const UPDATE_CONTACT_LENS_ANTERIOR_EYE_CHECK =
  "UPDATE_CONTACT_LENS_ANTERIOR_EYE_CHECK";
export const UPDATE_CONTACT_LENS_FLUORESEIN_CHECK =
  "UPDATE_CONTACT_LENS_FLUORESEIN_CHECK";
export const UPDATE_CONTACT_LENS_CONTACT_LENS_INITIAL_ORDER =
  "UPDATE_CONTACT_LENS_CONTACT_LENS_INITIAL_ORDER";
export const UPDATE_CONTACT_LENS_CONTACT_LENS_FINAL =
  "UPDATE_CONTACT_LENS_CONTACT_LENS_FINAL";
export const UPDATE_CONTACT_LENS_CONTACT_LENS_FITTING =
  "UPDATE_CONTACT_LENS_CONTACT_LENS_FITTING";
export const UPDATE_CONTACT_LENS_CONTACT_RECOMMENDATION =
  "UPDATE_CONTACT_LENS_CONTACT_RECOMMENDATION";
export const CLEAR_CONTACT_LENS_RECORD = "CLEAR_CONTACT_LENS_RECORD";

//contact lenses
export const LOADING_GET_CONTACT_LENSES = "LOADING_GET_CONTACT_LENSES";
export const GET_CONTACT_LENSES_ERROR = "GET_CONTACT_LENSES_ERROR";
export const SET_CONTACT_LENSES = "SET_CONTACT_LENSES";
export const LOADING_GET_CONTACT_LENS_STATUS =
  "LOADING_GET_CONTACT_LENS_STATUS";
export const SET_CONTACT_LENSES_STATUS = "SET_CONTACT_LENSES_STATUS";
export const GET_CONTACT_LENS_STATUS_ERROR = "GET_CONTACT_LENS_STATUS_ERROR";

export const LOADING_UPDATE_CONTACT_LENS_STATUS =
  "LOADING_UPDATE_CONTACT_LENS_STATUS";
export const UPDATE_CONTACT_LENS_STATUS = "UPDATE_CONTACT_LENS_STATUS";
export const UPDATE_CONTACT_LENS_STATUS_ERROR =
  "UPDATE_CONTACT_LENS_STATUS_ERROR";

//contact lens
export const LOADING_GET_CONTACT_LENS = "LOADING_GET_CONTACT_LENS";
export const GET_CONTACT_LENS_ERROR = "GET_CONTACT_LENS_ERROR";
export const SET_CONTACT_LENS = "SET_CONTACT_LENS";
// add contact lens
export const LOADING_ADD_CONTACT_LENS = "LOADING_ADD_CONTACT_LENS";
export const ADD_CONTACT_LENS_ERROR = "ADD_CONTACT_LENS_ERROR";
export const ADD_CONTACT_LENS = "ADD_CONTACT_LENS";
export const ADD_CONTACT_LENS_PATIENT = "ADD_CONTACT_LENS_PATIENT";
// delete contact lens
export const LOADING_DELETE_CONTACT_LENS = "LOADING_DELETE_CONTACT_LENS";
export const DELETE_CONTACT_LENS_ERROR = "DELETE_CONTACT_LENS_ERROR";
export const DELETE_CONTACT_LENS = "DELETE_CONTACT_LENS";
// delete contact lens
export const LOADING_UPDATE_CONTACT_LENS = "LOADING_UPDATE_CONTACT_LENS";
export const UPDATE_CONTACT_LENS_ERROR = "UPDATE_CONTACT_LENS_ERROR";
export const UPDATE_CONTACT_LENS = "UPDATE_CONTACT_LENS";

export const LOADING_CREATE_HELP_MESSAGE = "LOADING_CREATE_HELP_MESSAGE";
export const LOADING_GET_HELP_MESSAGE = "LOADING_GET_HELP_MESSAGE";
export const ADD_HELP_MESSAGE_ERROR = "ADD_HELP_MESSAGE_ERROR";
export const CREATE_HELP_MESSAGE = "CREATE_HELP_MESSAGE";
export const EDIT_HELP_MESSAGE = "EDIT_HELP_MESSAGE";
export const GET_HELP_MESSAGE = "GET_HELP_MESSAGE";

export const SET_PATIENT_CONTACT_LENS_STATUS =
  "SET_PATIENT_CONTACT_LENS_STATUS";
export const SELECT_CONTACT_LENS = "SELECT_CONTACT_LENS";

//STOCK TAKE
export const ADD_STOCK_TAKE = "ADD_STOCK_TAKE";
export const GET_STOCK_TAKE = "GET_STOCK_TAKE";
export const GET_ALL_STOCK_TAKE = "GET_ALL_STOCK_TAKE";
export const LOADING_STOCK_TAKE = "LOADING_STOCK_TAKE";
export const STOP_LOADING_STOCK_TAKE = "STOP_LOADING_STOCK_TAKE";

export const LOADING_GET_CLINICS = "LOADING_GET_CLINICS";
export const SET_CLINICS = "SET_CLINICS";
export const GET_CLINICS_ERROR = "GET_CLINICS_ERROR";
// add todo
export const LOADING_ADD_CLINICS = "LOADING_ADD_CLINICS";
export const ADD_CLINICS = "ADD_CLINICS";
export const ADD_CLINICS_ERROR = "ADD_CLINICS_ERROR";
// delete todo
export const LOADING_DELETE_CLINICS = "LOADING_DELETE_CLINICS";
export const DELETE_CLINICS = "DELETE_CLINICS";
export const DELETE_CLINICS_ERROR = "DELETE_CLINICS_ERROR";
// delete todo
export const LOADING_UPDATE_CLINICS = "LOADING_UPDATE_TODO";
export const UPDATE_CLINICS = "UPDATE_CLINICS";
export const UPDATE_CLINICS_ERROR = "UPDATE_CLINICS_ERROR";
//RECONCILIATION
export const ADD_RECONCILIATION = "ADD_RECONCILIATION";
export const GET_RECONCILIATION = "GET_RECONCILIATION";
export const GET_ALL_RECONCILIATION = "GET_ALL_RECONCILIATION";
export const LOADING_RECONCILIATION = "LOADING_RECONCILIATION";
export const STOP_LOADING_RECONCILIATION = "STOP_LOADING_RECONCILIATION";

export const SET_PRE_TESTS = "SET_PRE_TESTS";
export const SET_PRE_TEST = "SET_PRE_TEST";
export const PRE_TEST_ERRORS = "PRE_TEST_ERRORS";
export const LOADING_PRE_TEST = "LOADING_PRE_TEST";
// add pre test
export const ADD_PRE_TEST = "ADD_PRE_TEST";
export const LOADING_ADD_PRE_TEST = "LOADING_ADD_PRE_TEST";
export const ADD_PRE_TEST_ERRORS = "ADD_PRE_TEST_ERRORS";
// update pre test
export const UPDATE_PRE_TEST = "UPDATE_PRE_TEST";
export const LOADING_UPDATE_PRE_TEST = "LOADING_UPDATE_PRE_TEST";
export const UPDATE_PRE_TEST_ERRORS = "UPDATE_PRE_TEST_ERRORS";

export const SET_LIVE_QUES = "SET_LIVE_QUES";
export const SET_LIVE_QUE = "SET_LIVE_QUE";
export const LIVE_QUE_ERRORS = "LIVE_QUE_ERRORS";
export const LOADING_LIVE_QUE = "LOADING_LIVE_QUE";
// add live que
export const ADD_LIVE_QUE = "ADD_LIVE_QUE";
export const LOADING_ADD_LIVE_QUE = "LOADING_ADD_LIVE_QUE";
export const ADD_LIVE_QUE_ERRORS = "ADD_LIVE_QUE_ERRORS";
// update live que
export const UPDATE_LIVE_QUE = "UPDATE_LIVE_QUE";
export const LOADING_UPDATE_LIVE_QUE = "LOADING_UPDATE_LIVE_QUE";
export const UPDATE_LIVE_QUE_ERRORS = "UPDATE_LIVE_QUE_ERRORS";

export const SET_LUNCH_BREAKS = "SET_LUNCH_BREAKS";
export const SET_LUNCH_BREAK = "SET_LUNCH_BREAK";
export const LUNCH_BREAK_ERRORS = "LUNCH_BREAK_ERRORS";
export const LOADING_LUNCH_BREAK = "LOADING_LUNCH_BREAK";
// add live que
export const ADD_LUNCH_BREAK = "ADD_LUNCH_BREAK";
export const LOADING_ADD_LUNCH_BREAK = "LOADING_ADD_LUNCH_BREAK";
export const ADD_LUNCH_BREAK_ERRORS = "ADD_LUNCH_BREAK_ERRORS";
// update live que
export const UPDATE_LUNCH_BREAK = "UPDATE_LUNCH_BREAK";
export const LOADING_UPDATE_LUNCH_BREAK = "LOADING_UPDATE_LUNCH_BREAK";
export const UPDATE_LUNCH_BREAK_ERRORS = "UPDATE_LUNCH_BREAK_ERRORS";
// Direct Debit
export const ADD_DIRECTDEBIT = "ADD_DIRECTDEBIT";
export const GET_DIRECTDEBIT = "GET_DIRECTDEBIT";
export const GET_ALLDIRECTDEBIT = "GET_ALLDIRECTDEBIT";
export const LOADING_DIRECTDEBIT = "LOADING_DIRECTDEBIT";
export const STOP_LOADING_DIRECTDEBIT = "STOP_LOADING_DIRECTDEBIT";
export const UPDATE_DIRECTDEBIT = "UPDATE_DIRECTDEBIT";

// Photo Permission
export const ADD_PHOTOPERMISSION = "ADD_PHOTOPERMISSION";
export const GET_PHOTOPERMISSION = "GET_PHOTOPERMISSION";
export const GET_ALLPHOTOPERMISSION = "GET_ALLPHOTOPERMISSION";
export const LOADING_PHOTOPERMISSION = "LOADING_PHOTOPERMISSION";
export const STOP_LOADING_PHOTOPERMISSION = "STOP_LOADING_PHOTOPERMISSION";
export const UPDATE_PHOTOPERMISSION = "UPDATE_PHOTOPERMISSION";

// Useful Links
export const ADD_USEFULLINK = "ADD_USEFULLINK";
export const GET_USEFULLINK = "GET_USEFULLINK";
export const GET_ALLUSEFULLINK = "GET_ALLUSEFULLINK";
export const SET_USEFULLINKS = "SET_USEFULLINKS";
export const LOADING_USEFULLINK = "LOADING_USEFULLINK";
export const LOADING_ADD_USEFULLINK = "LOADING_ADD_USEFULLINK";
export const STOP_LOADING_USEFULLINK = "STOP_LOADING_USEFULLINK";
export const UPDATE_USEFULLINK = "UPDATE_USEFULLINK";
export const DELETE_USEFULLINK = "DELETE_USEFULLINK";
export const USEFULLINK_ERRORS = "USEFULLINK_ERRORS";
